


















































import { trimSpaceToUnderscore } from "@/helpers/common";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseDetailProduct } from "@/models/interface-v2/product.interface";
import { productService } from "@/services-v2/product.service";
import { initProduct } from "@/store/resource/product.resource";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

enum MENU_TABS {
  PRODUCT = "product",
  SETTING_ACCOUNT = "setting_account",
  UOM_CONVERSION = "uom_conversion",
  PRODUCT_ITEM_CODES = "product_item_codes",
}

interface ITabs {
  label: string;
  key: MENU_TABS;
  comp:
    | "Tab-Product"
    | "Tab-Setting-Account"
    | "Tab-UOM-Conversion"
    | "Tab-Product-Item-Codes";
}

const tabs: ITabs[] = [
  {
    label: "lbl_product",
    key: MENU_TABS.PRODUCT,
    comp: "Tab-Product",
  },
  {
    label: "lbl_setting_account",
    key: MENU_TABS.SETTING_ACCOUNT,
    comp: "Tab-Setting-Account",
  },
  {
    label: "lbl_uom_conversion",
    key: MENU_TABS.UOM_CONVERSION,
    comp: "Tab-UOM-Conversion",
  },
  {
    label: "lbl_item_codes",
    key: MENU_TABS.PRODUCT_ITEM_CODES,
    comp: "Tab-Product-Item-Codes",
  },
];

const { mapMutations, mapState } = createNamespacedHelpers("productStoreV2");

export default Vue.extend({
  name: "CreateProductv2",
  components: {
    "Tab-Product": () => import("./Product.vue"),
    "Tab-Setting-Account": () => import("./SettingAccount.vue"),
    "Tab-UOM-Conversion": () => import("./UomConversionV2.vue"),
    "Tab-Product-Item-Codes": () => import("./ProductItemCodes.vue"),
  },
  mixins: [MNotificationVue],
  props: {
    id: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      tabs,
      activeTabPane: MENU_TABS.PRODUCT as MENU_TABS,
      mode: this.$route.meta.mode as "edit" | "create",
    };
  },
  computed: {
    dynamicComp():
      | "Tab-Product"
      | "Tab-Setting-Account"
      | "Tab-UOM-Conversion"
      | "Tab-Product-Item-Codes"
      | string {
      return (
        this.tabs.find((tab) => tab.key === this.activeTabPane)?.comp || ""
      );
    },
    ...mapState({
      storeProduct: (state: any) => state.theProduct,
    }),
  },
  methods: {
    ...mapMutations(["SET_PRODUCT"]),
    onTabChange(value: MENU_TABS): void {
      this.activeTabPane = value;
    },
    hasDuplicate(uomConversions: { unitUom: string }[]): boolean {
      let i: number, j: number;
      let duplicate = false;
      for (i = 0; i < uomConversions.length; i++) {
        const e1 = uomConversions[i];
        for (j = 0; j < uomConversions.length; j++) {
          const e2 = uomConversions[j];
          if (i !== j) {
            if (e1.unitUom === e2.unitUom) {
              duplicate = true;
              break;
            }
          }
        }
        if (duplicate) {
          break;
        }
      }
      return duplicate;
    },
    /**
     * do product validation
     */
    isProductValid(): { valid: boolean; err: string[] } {
      const {
        productCategoryId,
        uomConversions,
        productAccount,
        supplierId,
        type,
        name,
        parent,
        parentId,
        baseUnit,
        minimumStock,
        cutsCodeId,
      } = this.storeProduct;

      const returnVal = {
        valid: true,
        err: [] as string[],
      };

      //#region check basic field
      const formValues = {
        productCategoryId,
        supplierId,
        type,
        name,
        baseUnit,
        cutsCodeId,
      };
      for (const key in formValues) {
        const el = formValues[key];
        if (!el) {
          returnVal.valid = false;
          returnVal.err.push(key);
          break;
        }
      }
      //#endregion

      //#region check parent
      if (!parent && !parentId) {
        returnVal.valid = false;
        returnVal.err.push("parentProduct");
      }
      //#endregion

      //#region check minimum stock
      if (minimumStock < 0) {
        returnVal.valid = false;
        returnVal.err.push("minimumStock");
      }
      //#endregion

      //#region check uom conversion
      if (!uomConversions.length) {
        returnVal.valid = false;
        returnVal.err.push("uomConversions");
      } else {
        const uomConv = uomConversions.find((item) => !item.unitUom);
        const match = uomConversions.find((item) => item.unitUom === baseUnit);
        if (!match || uomConv) {
          returnVal.valid = false;
          returnVal.err.push("uomConversionsNoUnit");
        }

        if (this.hasDuplicate(uomConversions)) {
          returnVal.valid = false;
          returnVal.err.push("uomConversionsDuplicate");
        }
      }
      //#endregion

      //#region check product account
      const excludeAccount = [
        "salesTaxId",
        "salesTaxName",
        "purchaseTaxId",
        "purchaseTaxName",
        "assetCostAccountId",
        "assetCostAccountName",
        "assetClearingAccountId",
        "assetClearingAccountName",
        "assetDepreciationAccountId",
        "assetDepreciationAccountName",
        "assetAccumulationAccountId",
        "assetAccumulationAccountName",
      ];
      for (const key in productAccount) {
        const el = productAccount[key];
        if (!el && !excludeAccount.includes(key)) {
          returnVal.valid = false;
          returnVal.err.push(key);
          break;
        }
      }
      //#endregion

      return returnVal;
    },
    saveProduct(): void {
      const { valid, err } = this.isProductValid();
      if (valid) {
        if (this.mode === "create") {
          this.createNewProduct();
        } else {
          this.updateProduct();
        }
      } else {
        const str = {
          productCategoryId: "lbl_product_category",
          supplierId: "lbl_supplier",
          type: "lbl_type",
          name: "lbl_product_name",
          baseUnit: "lbl_uom",
          cutsCodeId: "lbl_cuts",
          parentProduct: "lbl_parent_product",
          minimumStock: "lbl_minimum_stock",
          uomConversions: "lbl_uom_conversion",
          uomConversionsNoUnit: "lbl_uom_conversion_no_unit",
          uomConversionsDuplicate: "lbl_uom_conversion_duplicate",
          costOfSalesAccountId: "lbl_cost_of_sales_account",
          expensePurchaseAccountId: "lbl_expense_purchase_account",
          inventoryAccountId: "lbl_inventory_account",
          jobCostingAccountId: "lbl_job_costing_account",
          purchaseDiscountAccountId: "lbl_purchase_discount_account",
          purchaseReturnAccountId: "lbl_purchase_return_account",
          salesAccountId: "lbl_sales_account",
          salesDiscountAccountId: "lbl_sales_discount_account",
          salesReturnAccountId: "lbl_sales_return_account",
          unbilledAccountId: "lbl_unbilled_account",
        };
        const fields = err.map<string>((item) => this.$t(str[item]).toString());
        this.showNotifError("notif_product_invalid", {
          data: fields.join(", "),
        });
      }
    },
    resetFormatCurrency(product): void {
      if (product.uomConversions.length) {
        product.uomConversions.forEach((item) => {
          item.purchasePrice = item.purchasePrice + ""; // convert to string
          item.salePrice = item.salePrice + ""; // convert to string
          if (item.purchasePrice.includes(",")) {
            item.purchasePrice = parseInt(item.purchasePrice.replace(/,/g, ""));
          } else {
            item.purchasePrice = parseInt(item.purchasePrice);
          }

          if (item.salePrice.includes(",")) {
            item.salePrice = parseInt(item.salePrice.replace(/,/g, ""));
          } else {
            item.salePrice = parseInt(item.salePrice);
          }
        });
      }
    },
    createNewProduct(): void {
      const payload = this.constructPayload();
      this.resetFormatCurrency(payload);
      productService.createProduct(payload).then((res) => {
        this.$message.success(this.$t("notif_create_success").toString());
        this.getDetailProduct(res.id);
        this.SET_PRODUCT(initProduct());
      });
    },
    updateProduct(): void {
      const payload = this.constructPayload();
      this.resetFormatCurrency(payload);
      productService.updateProduct(payload, this.id).then(() => {
        this.$message.success(this.$t("notif_update_success").toString());
        this.SET_PRODUCT(initProduct());
        this.getDetailProduct(this.id);
      });
    },
    constructPayload() {
      const { type } = this.storeProduct;
      return {
        ...this.storeProduct,
        type: trimSpaceToUnderscore(type),
      };
    },
    mapProductStore(data: ResponseDetailProduct): void {
      const prod = {
        active: data.status,
        barcode: data.barcode,
        baseUnit: data.baseUnit,
        brand: data.brand,
        deletedItemCodeIds: [],
        deletedUomConversionIds: [],
        description: data.description,
        code: data.code,
        cutsCode: data.cutsCode,
        cutsCodeId: data.cutsCodeId,
        image: data.image,
        itemCodes: data.itemCodes,
        minimumStock: data.minimumStock,
        name: data.name,
        parent: data.parent,
        parentId: data.parentId,
        parentName: data.parentName,
        productAccount: {
          costOfSalesAccountId: data.costOfSalesAccountId,
          costOfSalesAccountName: data.costOfSalesAccountName,
          expensePurchaseAccountId: data.expensePurchaseAccountId,
          expensePurchaseAccountName: data.expensePurchaseAccountName,
          inventoryAccountId: data.inventoryAccountId,
          inventoryAccountName: data.inventoryAccountName,
          jobCostingAccountId: data.jobCostingAccountId,
          jobCostingAccountName: data.jobCostingAccountName,
          purchaseDiscountAccountId: data.purchaseDiscountAccountId,
          purchaseDiscountAccountName: data.purchaseDiscountAccountName,
          purchaseReturnAccountId: data.purchaseReturnAccountId,
          purchaseReturnAccountName: data.purchaseReturnAccountName,
          purchaseTaxId: data.purchaseTaxId,
          purchaseTaxName: data.purchaseTaxName,
          salesAccountId: data.salesAccountId,
          salesAccountName: data.salesAccountName,
          salesDiscountAccountId: data.salesDiscountAccountId,
          salesDiscountAccountName: data.salesDiscountAccountName,
          salesReturnAccountId: data.salesReturnAccountId,
          salesReturnAccountName: data.salesReturnAccountName,
          salesTaxId: data.salesTaxId,
          salesTaxName: data.salesTaxName,
          unbilledAccountId: data.unbilledAccountId,
          unbilledAccountName: data.unbilledAccountName,
        },
        productCategoryId: data.categoryId,
        categoryName: data.categoryName,
        trackAsAsset: data.trackAsAsset,
        trackAsInventory: data.trackAsInventory,
        trackAsPurchase: data.trackAsPurchase,
        trackAsSell: data.trackAsSell,
        type: data.type,
        supplierId: data.supplierId,
        supplierCode: data.supplierCode,
        supplierFullName: data.supplierFullName,
        uomConversions: data.uomConversions,
        id: data.id,
      };
      this.SET_PRODUCT(prod);
    },
    getDetailProduct(productId: string): void {
      productService.detailProduct(productId).then((res) => {
        this.mapProductStore(res);
      });
    },
    handleBack(): void {
      this.$router.push("/logistic/setupproduct/read");
    },
  },
});
