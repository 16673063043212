var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_create_product") } },
    [
      _c(
        "a-tabs",
        {
          attrs: {
            "default-active-key": _vm.activeTabPane,
            "active-key": _vm.activeTabPane
          },
          on: { change: _vm.onTabChange }
        },
        _vm._l(_vm.tabs, function(tab) {
          return _c("a-tab-pane", { key: tab.key }, [
            _c(
              "span",
              { attrs: { slot: "tab" }, slot: "tab" },
              [
                _vm._v(" " + _vm._s(_vm.$t(tab.label)) + " "),
                _c("a-icon", {
                  staticStyle: { color: "red" },
                  attrs: { type: "tag" }
                })
              ],
              1
            )
          ])
        }),
        1
      ),
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [_c("keep-alive", [_c(_vm.dynamicComp, { tag: "component" })], 1)],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "save", type: "primary" },
                      on: { click: _vm.saveProduct }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }